@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.login-form-container {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    margin-top: 50px;
    border-color: white;
    background-color: #000e1f;
    font-family: 'Poppins', sans-serif;
}

.login-form-container .fields {
    border-radius: 20px;
    margin-bottom: 15px;
    color: #00c3b1;
    background-color: #010102;
    border-color: white;
}

.login-form-container .fields::placeholder {
    color: #00c3b1;
}

.login-form-container .fields:focus {
    background-color: #010102;
    color: #00c3b1;
}

.login-form-container .submit-button {
    margin-top: 25px;
    text-align: center;
}

.horizontal-center {
    display: flex;
    justify-content: center;
}

.login-form-container .sign-in-button {
    width: 50%;
    border-radius: 20px;
    background-color: #00c3b1;
    border-color: white;
    color: #010102;
}

.login-form-container .sign-in-button:hover {
    background-color: #010102;
    color: white;
    border-color: white;
}

.login-form-container .sign-up-link-parent  {
    margin-top: 15px;
    text-align: center;
}

.sign-up-link {
    color: white;
    font-size: small;
}

.sign-up-link:hover {
    color: #00c3b1;
}
  