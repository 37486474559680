@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body, html {
    height: 100%;
    margin: 0;
}

.basic-home-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

/* Header Styles */
.header-div {
    margin-top: 3%; /* Reduced top margin */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.microscope-logo {
    width: 15vw; /* Use viewport width for dynamic scaling */
    max-width: 200px; /* Max size to avoid overflow */
    height: auto;
    margin-bottom: 10px;
}

.prospectnav-title {
    font-family: 'Poppins', sans-serif;
    font-size: 4vw; /* Use viewport width for responsive font size */
    font-weight: bold;
    color: white;
    margin: 0;
    line-height: 1.2; /* Adjust line height */
}

/* General Styles */
.arrow-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 10px; /* Space between arrows and image */
}

.arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Media Query for Mobile Adjustments */
@media (max-width: 768px) {
    .microscope-logo {
        width: 25vw;
        max-width: 120px;
    }

    .prospectnav-title {
        font-size: 2.5rem;
    }
    
    .arrow-button {
        width: 40px;
        height: 40px;
    }

    /* Adjust the arrows and image container for mobile */
    .arrow-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slider-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .order-mobile-1 {
        order: 1;
    }

    .order-mobile-2 {
        order: 2;
    }

    .align-items-center {
        display: flex;
        flex-direction: column;
    }

    
}

.basic-home-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh; /* Ensure the container takes full viewport height */
}

.basic-home-container .h1-header-div {
    margin-top: 10%; /* Adjusted margin */
    width: 50%;
    margin-left: 25%;
    text-align: center;
}

.basic-home-container .h1-header-div p {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 70px;
}
.basic-home-container .h2-header-div p {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 36px;
}

.basic-home-container .additional-info-div {
    margin-top: 15px; /* Adjusted margin */
    width: 80%;
    margin-left: 10%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: larger;
}

.basic-home-container .flow-div {
    margin-top: 65px; /* Adjusted margin */
    width: 90%;
    margin-left: 5%;
    height: fit-content;
    text-align: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: large;
}

.flow-div .row {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
}

.basic-home-container .flow-div .right-arrow-div {
    font-size: 70px;
}

.basic-home-container .info-div {
    margin-top: 65px; /* Adjusted margin */
    background-color: #00c3b1;
    height: fit-content;
    width: 40%; /* Adjusted width */
    margin-left: 30%; /* Centered div */
    border-radius: 20px; /* Adjusted border radius */
    padding: 30px 10px 10px; /* Reduced top and bottom padding */
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: xx-large; /* Adjusted font size */
    color: white;
}



.basic-home-container .text-box-div {
    background-color: #1f2937; /* Background color for textboxes */
    height: fit-content;
    width: 100%; /* Adjusted width */
    border-radius: 10px; /* Border radius for textboxes */
    padding: 15px; /* Padding for textboxes */
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: large; /* Font size for textboxes */
    margin-top: 30px; /* Added margin to lower textboxes */
}

.image-placeholder {
    height: 200px; /* Placeholder height */
    width: 100%; /* Placeholder width */
    border-radius: 10px; /* Border radius for placeholders */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: large;
    background-color: transparent; /* Make background transparent */
    border: 2px dashed #ccc; /* Optional: Add a dashed border to indicate the placeholder */
    margin-top: 30px; /* Added margin to lower placeholders */
}

.banner-div {
    background-color: #00c3b1;
    color: white;
    text-align: center;
    height: 100px; /* Set a fixed height */
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    border-radius: 15px;
    font-family: 'Poppins', sans-serif;
    font-size: x-large;
    width: 75%; /* Set the width to make the banner less wide */
    margin-top: 100px;
}

.banner-div p {
    margin: 0; /* Remove default margins */
    line-height: 1; /* Ensure line-height is normal */
}


/* Subscription Section Styles */
.subscription-section {
    margin-top: 40px;
    display: flex; /* Aligns items side-by-side */
    justify-content: center; /* Centers the items */
    gap: 10px; /* Controls the space between the cards */
}

.subscription-card {
    background-color: #2d3748;
    color: white;
    border-radius: 15px;
    padding: 30px 20px;
    text-align: center;
    width: 100%;
    max-width: 300px; /* Ensures it looks good on larger screens */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subscription-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    color: #00c3b1;
    margin-bottom: 30px;
}

.subscription-card h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.subscription-card p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.free-plan {
    background-color: #2a4365; /* Dark blue background for the Free plan */
}

.pro-plan {
    background-color: #00c3b1; /* Teal background for the Pro plan */
}

.price {
    font-size: 2rem;
    font-weight: bold;
    color: white; /* Text color to match the plan theme */
}

.old-price {
    font-size: 1.2rem;
    text-decoration: line-through;
    color: #90cdf4; /* Light blue for strikethrough price */
}

.subscription-footer {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: white;
    margin-top: 20px;
}
