@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.bubble-textbox {
    margin-top: 8%;
    padding: 2.5%;
    border-radius: 25px;
    background-color: #D4AC3C;
    color: black;
    font-size: 16px;
    font-style: italic;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    border-color: white;
}

.sign-up-form {
    width: 80%;
    margin-left: 10%;
    padding: 20px;
}

.signup-form-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    border-color: white;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    padding: 20px;
    text-align: center;
}

.col-title {
    color: #00c3b1;
    margin-bottom: 30px;
}

.sign-up-form .button-row {
    margin-top: 25px;
    text-align: center;
}

.sign-up-form .sign-up-button {
    width: 50%;
    border-radius: 20px;
    background-color: #00c3b1;
    border-color: white;
    color: #010102;
    font-family: 'Poppins', sans-serif;
}

.sign-up-form .sign-up-button:hover {
    background-color: #010102;
    color: white;
    border-color: #00c3b1;
}

.signup-form-container .fields {
    border-radius: 20px;
    margin-bottom: 15px;
    color: #00c3b1;
    background-color: #010102;
    border-color: white;
}

.signup-form-container .fields::placeholder {
    color: #00c3b1;
}

.signup-form-container .fields:focus {
    background-color: #010102;
    color: #00c3b1;
}

.horizontal-center {
    display: flex;
    justify-content: center;
}
  