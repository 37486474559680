@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.stripe-payment-element-wrapper {
    padding-top: 30px;
    font-family: 'Poppins', sans-serif;
    width: 50%;
    margin-left: 25%;
}

.stripe-payment-element-wrapper .pay-button-row {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display:flex;
    justify-content: end;
}

.stripe-payment-element-wrapper .pay-button {
    height: 40px;
    width: fit-content;
    background-color: #00c3b1;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px solid white;
}