@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.target-account-list-div {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.target-account-list-div .header-row {
    background-color: #00c3b1;
    text-align: center;
    font-size: x-large;
    border: 1px solid white;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.target-account-list-div .target-account-cell-div {
    border: 1px solid white;
    text-align: center;
    font-size: large;
    padding: 15px 5px 15px 5px;
}

.target-account-cell-div.selected {
    background-color: #f6c710;
    color: black;
}

.target-account-list-div .target-account-cell-div:hover {
    cursor: pointer;
}

.scrollable-container {
    max-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid white;
}