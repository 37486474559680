@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.email-div .emailTabs .nav-link.active {
    background-color: #00c3b1; /* Change this to your desired background color */
    color: white; /* Optional: Change the text color if needed */
    font-family: 'Poppins', sans-serif;
}

.emailTabs .nav-link {
    color: white; /* Change this to your desired text color for inactive tabs */
}

.email-div pre {
    font-family: 'Poppins', sans-serif;
}