@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.subscription-container {
    font-family: 'Poppins', sans-serif;
    padding-left: 20px;
    color: #00c3b1;
}

.subscription-container .tokens-left-text {
    padding-left: 20px;
}