@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.generation-result-div {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.generation-result-div .generation-result-header-row {
    text-align: center;
    font-size: large;
    padding-left: 10px;
    padding-right: 10px;
    height: 100px;
}

.generation-result-div .custom-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 20%;
    max-width: 20%;
    text-align: center;
    max-width: 20%;
    border: 1px solid white;
    padding: 15px 5px 15px 5px;
    height: 100px;
}

.generation-result-div .custom-col:hover {
    cursor: pointer;
}

.custom-col.selected {
    background-color: #00c3b1;
}

.generation-result-div .result-div {
    padding-left: 10px;
    padding-right: 10px;
}

.generation-result-div .content-row {
    padding: 20px;
    border: 1px solid white;
    height: 800px;
    overflow-y: auto;
}

.generation-result-div pre {
    font-family: 'Poppins', sans-serif;
}