@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.company-info {
    font-family: 'Poppins', sans-serif;
    padding-left: 20px;
    color: #00c3b1;
}

.company-info .company-info-form-container {
    padding-top: 30px;
}

.company-info .company-info-form-container .form-item {
    padding-bottom: 10px;
}

.company-info .company-info-form-container .form-control-field {
    background-color: #010102;
    color: #00c3b1;
}

.company-info .company-info-form-container .form-control-field::placeholder {
    color: #00c3b1;
}

.company-info .company-info-form-container .form-control-field:focus {
    background-color: #010102;
    color: #00c3b1;
}

.company-info .button-container {
    display: flex;
    justify-content: flex-end;
}

.company-info .button-container .in-view-mode-buttons {
    width: 10%;
}

.company-info .button-container .in-view-mode-buttons .edit-button {
    width: 100%;
}

.company-info .button-container .in-edit-mode-buttons {
    width: fit-content;
}

.company-info .button-container .button {
    border-radius: 20px;
    background-color: #00c3b1;
    border-color: white;
    color: #010102;
    font-family: 'Poppins', sans-serif;
}

.company-info .button-container .button:hover {
    background-color: #010102;
    color: white;
    border-color: #00c3b1;
}

.company-info .button-container .secondary {
    margin-left: 10px;
    background-color: grey;
    color: white;
}

.company-info .button-container .secondary:hover {
    background-color: grey;
    color: #010102;
    border-color: white;
}