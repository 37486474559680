.resultDiv{
    margin-top: 30px;
    align-items: center;
    background-color: #e4e3e3;
    padding:10px 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: black;
}

.displayTabs{
    padding:10px 15px;
    color: black; 
}

h4{
    text-align: left;
    color:black !important;
    font-size: 15px !important;
}


h4:hover{
    color: #4b4b4b !important; 
}