@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.generation-result-main-container {
    width: 100%;
}

.generation-result-main-container .generate-row-div {
    display: flex;
    justify-content: flex-end;
}

.generation-result-main-container .generate-row {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #00c3b1;
    border: 1px solid black;
    border-radius: 30px;
    font-size: larger;
    font-family: 'Poppins', sans-serif;
}

.generation-result-main-container .generate-row:hover {
    cursor: pointer;
}

pre {
    font-family: 'Poppins', sans-serif;
}