.formData {
  margin: 20px auto;
  width: 70%;
  padding: 30px;
  /* background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

h3{
  text-align: center;
  margin: 10px;
  padding: 10px;
  
}

.formRow {
  margin-bottom: 20px;
  align-items: center;
  background-color: #e4e3e3;
  padding:10px 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.formCol{
  background-color: #e4e3e3;
  padding:10px 15px;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
 
}
.submitButton{
  background-color: #333;
  color:white;
  border: none;
}



.generatedEmails {
  margin-top: 20px;
}

.error {
  margin-top: 20px;
  color: red;
}