@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.modal-container .modal-content{
    font-family: 'Poppins', sans-serif;
    margin-top: 300px;
}

.modal-container .modal-content .modal-header {
    color: #010102;
    /* border-color: #010102; */
    border: 0px;
}

.modal-container .modal-content .modal-footer {
    /* border-color: #010102; */
    border: 0px;
    padding-top: 0px;
}

.generate-content-fields-container .fields {
    border-radius: 10px;
    margin-bottom: 15px;
    color: black;
    /* background-color: #010102; */
    border-color: black;
}

.generate-content-fields-container .fields::placeholder {
    color: black;
}

.generate-content-fields-container .fields:focus {
    background-color: white;
    color: black;
}

.modal-container .modal-generate-button {
    background-color: #00c3b1;
    color: #010102;
    border: 1px solid #010102;
    border-radius: 20px;
}