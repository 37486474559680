@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.settings-stack {
    padding-top: 30px;
    padding-left: 10px;
    color: #00c3b1;
    height: 100%;
    border-right: 1px solid white;
}

.settings-stack .settings-stack-item {
    padding-bottom: 15px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    padding-left: 5px;
}

.settings-stack .settings-stack-item:hover {
    font-weight: bold;
}

